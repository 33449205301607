import { useEffect } from 'react';

const HamburgerSV = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 1034);
    localStorage.setItem('club_name', 'Hamburger SV - Nur der HSV');

    window.location.assign('/');
  });

  return null;
};
export default HamburgerSV;
